<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="60%"
    :close-on-click-modal="false"
    @close="handleClose"
    v-loading="loading"
    :showClose="showclose"
  >
    <div>
      <div class="dialog_container">
        <el-card class="dialog_container_left">
          <div class="dialog_container_left_top">
            <img
              :src="data.customPicUrl? data.customPicUrl:'https://dev-uat-alanber.oss-cn-chengdu.aliyuncs.com/4FE9D265B1EF406FA50B208FE59A6A34.png'"
              alt=""
            />
            <div class="customName">{{ data.customName }}</div>
            <div class="vipCode">会员号：{{ data.vipCode }}</div>
          </div>
          <p><span> 手机号:</span>&emsp;<span>{{ data.tel1 }}</span></p>
          <p><span> 产品类型:</span>&emsp;<span>{{ data.taskName }}</span></p>
          <p><span> 机构:</span>&emsp;<span>{{ data.companyName }}</span></p>
          <p><span> 预约类型:</span>&emsp;<span>{{ data.samplingName }}</span></p>
          <p><span> 医生:</span>&emsp;<span>{{ data.docterName }}</span></p>
          <p><span> 护士:</span>&emsp;<span>{{ data.nurseName }}</span></p>
          <p><span> 营养师:</span>&emsp;<span>{{ data.dietitianName }}</span></p>
          <p><span> 上门时间:</span>&emsp;<span>{{ data.taskStartTime1 }}</span></p>
          <p><span> 完成时间:</span>&emsp;<span>{{ data.taskEndTime1 }}</span></p>
          <p><span> 地址:</span>&emsp;<span>{{ data.address }}</span></p>
        </el-card>
        <div class="dialog_container_right">
          <el-card style="display: flex; align-items: center; margin-bottom: 20px; width: 100%">
            <span style="width: 80px; margin-right: 20px">任务状态:</span>
            <el-select v-model="selectdate" placeholder="请选择任务状态" :disabled="option ? false : true">
              <el-option
                :label="obj.name"
                :value="obj.val"
                v-for="(obj, i) in option ? option : optionlist"
                :key="i"
              ></el-option>
            </el-select>
          </el-card>
          <el-card style="flex: 1">
            <p>历史进度:</p>
            <div style="margin-left: 80px">
              <div class="line"></div>
              <div class="outBox" v-for="(item, index) in historylist" :key="index">
                <div class="word">
                  <div id="outer_point" :style="outerPoint(index)">
                    <div id="point" :style="point(index)"></div>
                  </div>
                  &emsp;&emsp; <span class="word_time">{{ item.time }}</span
                  >&emsp;&emsp;
                  <span class="word_processName">{{ item.processName }}</span>
                </div>
                <div class="line"></div>
              </div>
            </div>
          </el-card>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" class="hovercancel">取 消</el-button>
        <el-button type="primary" @click="dialogVisibles" class="hoverdefine">确 定</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { postRequestAnthor } from '@/api/index'
export default {
  props: ['dates'],
  data() {
    return {
      dialogVisible: true,
      type: 1,
      data: '',
      datatime: '',
      optionlist: [
        { name: '已确认预约', val: '301' },
        { name: '2小时内上门', val: '302' },
        { name: '已采样', val: '401' },
        { name: '已送检', val: '501' },
        { name: '已完成', val: '601' },
      ],
      option: '', //动态任务栏数据
      selectdate: '',
      loading: true,
      showclose: false,
      historylist: '', //动态历史记录
      outerPoints: [
        'border: 1px solid #A9D900',
        'border: 1px solid #5764F1',
        'border: 1px solid #FF8F00',
        'border: 1px solid #FF8B00',
        'border: 1px solid #FF0000',
        'border: 1px solid pink',
        'border: 1px solid #A9D900',
        'border: 1px solid #5764F1',
        'border: 1px solid #FF8F00',
        'border: 1px solid #FF8B00',
        'border: 1px solid #FF0000',
        'border: 1px solid pink',
      ],
      points: [
        'background: #A9D900;',
        'background:  #5764F1;',
        'background: #FF8F00;',
        'background:  #FF8B00;',
        'background: #FF0000;',
        'background: pink;',
        'background: #A9D900;',
        'background:  #5764F1;',
        'background: #FF8F00;',
        'background:  #FF8B00;',
        'background: #FF0000;',
        'background: pink;',
      ],
    }
  },
  created() {
    // 获取页面数据
    this.loaddata()
    // 获取动态下拉状态任务栏
    // console.log(this.dates)
    this.optionlist.map((objs, items) => {
      if (objs.name == this.dates.subStatusName) {
        this.option = this.optionlist.slice(items + 1)
        return
      }
    })
    this.option ? this.option : this.optionlist
  },
  methods: {
    // 获取页面数据方法
    loaddata() {
      postRequestAnthor('/samplingComputer/querySamplingInfo', { samplingType: [1], id: this.dates.id })
        .then(res => {
          this.data = res[0]
          console.log(this.data)
          this.historylist = this.data.samplingTaskHistoryVos.reverse()
          // console.log(this.historylist);
          this.optionlist.map((obj, i) => {
            if (obj.name == res[0].statusName) {
              return
            }
          })
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleClose() {
      this.$emit('closeTheDoor')
    },
    changeType(val) {
      this.type = val
    },
    outerPoint(i) {
      return this.outerPoints[i]
    },
    point(i) {
      return this.points[i]
    },
    // 确定修改
    dialogVisibles() {
      if (this.selectdate) {
        // 发送确认请求
        postRequestAnthor('/samplingComputer/updateSamplingStatus', {
          id: this.dates.id,
          samplingType: 1,
          subStatus: this.selectdate,
          oldStatus: this.dates.status,
          oldSubStatus: this.dates.substatus,
          updateBy: '1',
        }).then(() => {
          this.dialogVisible = false
          this.loaddata()
          this.$message({
            message: '状态修改成功',
            type: 'success',
          })
          // this.handleClose()
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog_container {
  margin-top:-16px;
  display: flex;
  .dialog_container_right {
    // background-color: #f7f8fa;
    flex: 6.6;
    display: flex;
    flex-direction: column;
    // justify-content: space-around;
    // padding: 20px;
    margin-top: -20px;
  }
  .dialog_container_left {
    flex: 3.4;
    display: flex;
    flex-direction: column;
    margin: -20px 20px 0 0;
    p {
      display: flex;
      align-items: center;
      margin: 12px;
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #676767;
      span{
        display: inline-block;
      }
    }
    .dialog_container_left_top {
      text-align: center;
      margin: 0;
      // padding: 14px;
      border-bottom: 1px solid #e5e5e5;
      div {
        text-align: center;
      }
      .customName {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #323232;
        margin-top:16px;
      }
      .vipCode {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #676767;
        margin:10px 0 16px;
      }
    }
  }
}
.el-card__body {
  width: 100% !important;
}
img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}
.button {
  margin-bottom: 20px;
}
.el-select {
  width: 70% !important;
}
.el_steps {
  width: 100%;
  overflow-x: auto;
}

.outBox {
  margin: 0 auto;
}
.word {
  display: flex;
  align-items: center;
  margin: 0 auto;
  #outer_point {
    margin-left: -2.5px;

    border-radius: 50%;
  }
  #point {
    width: 4px;
    height: 4px;
    margin: 1px;

    border-radius: 50%;
  }
  .word_time {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
  }
  .word_processName {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #444444;
  }
}
.line {
  width: 1px;
  height: 25px;
  background: #e6e6e6;
  margin: -5px 0 -5px 1px;
}
.dialog-footer {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
</style>
